//React
import React from 'react'
import { connect } from 'react-redux'

//Actions
import { saveLead } from '../../actions/saveLead'

//Icons
import { FaSpinner } from 'react-icons/fa'

//Redux
const mapStateToProps = ({ leadsubmitted, isLoading }) => {
  return { leadsubmitted, isLoading }
}
const mapDispatchToProps = (dispatch) => ({
  saveLead: (userData) => {
    dispatch(saveLead(userData))
  },
})

class Snippet extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      userName: '',
      userLastName: '',
      userTitle: '',
      userCompany: '',
      userEmail: '',
      userPhone: '',
      userEmployees: '',
      initialLoad: true,
      submitted: false,
      submitComplete: false,
      error: {
        userName: false,
        userLastName: false,
        userCompany: false,
        userEmail: false,
        userPhone: false,
        userEmployees: false,
      },
    }
  }

  componentDidMount() {
    if (this.props.leadSource) {
      this.setState({ leadSource: this.props.leadSource })
    }
  }

  handleInputChange = (event) => {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value,
      initialLoad: false,
    })
  }

  sendConversionEvent() {
    if (
      typeof window !== `undefined` &&
      process.env.STAGE !== 'dev' &&
      process.env.STAGE !== 'staging'
    ) {
      window.gtag('event', 'conversion', {
        send_to: 'AW-615742954/GdUSCK7N9NYBEOr7zaUC',
      })
    }
  }

  handleValidation(event) {
    event.preventDefault()

    const formData = this.state

    console.log('**formData')
    console.log(formData)
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/
    const validEmail = emailRegex.test(formData.userEmail)

    let errorObj = {
      userName: false,
      userLastName: false,
      userCompany: false,
      userEmail: false,
      userPhone: false,
      userEmployees: false,
    }
    if (!formData.userName) {
      // this.setError('userName')
      console.log('no name')
      errorObj.userName = true
    }
    if (!formData.userLastName) {
      // this.setError('userLastName')
      errorObj.userLastName = true

      // return
    }
    if (!formData.userCompany) {
      // this.setError('userCompany')
      errorObj.userCompany = true

      // return
    }
    if (!formData.userEmail || validEmail !== true) {
      // this.setError('userEmail')
      errorObj.userEmail = true

      // return
    }
    if (!formData.userPhone) {
      // this.setError('userPhone')
      errorObj.userPhone = true
      // return
    }
    if (!formData.userEmployees) {
      // this.setError('userEmployees')
      errorObj.userEmployees = true
      // return
    }
    console.log('errorObj')
    console.log(errorObj)
    console.log('this.state.error')
    console.log(this.state.error)

    this.setState({ error: errorObj })

    if (
      this.state.initialLoad === false &&
      Object.values(errorObj).every((value) => value === false)
    ) {
      this.handleSubmit()
    }
  }
  handleSubmit = () => {
    const formData = this.state

    console.log('formData')
    console.log(formData)

    this.sendConversionEvent()
    this.props.saveLead(formData)
  }

  render() {
    const { isLoading, leadsubmitted } = this.props

    return (
      <>
        {leadsubmitted === false ? (
          <div class="form-offer mb-3 pt-0 px-4 pb-4 w-100">
            <form class="border-2-blue-1 bg-gray-7 rounded-corners p-4 needs-validation">
              <div class="form-header">
                <p class="display-4 black-5 font-weight-light text-center">
                  {this.props.text}
                </p>
              </div>
              <div class="form-group">
                <label for="userName" class="d-none">
                  First name
                </label>
                <input
                  type="text"
                  class={`form-control ${
                    this.state.error.userName ? 'is-invalid' : ''
                  }`}
                  name="userName"
                  id="userName"
                  placeholder="First name"
                  value={this.state.userName}
                  onChange={this.handleInputChange}
                />
                {/* <div class="invalid-feedback">Please enter your first name</div> */}
              </div>
              <div class="form-group">
                <label for="userLastName" class="d-none">
                  Last name
                </label>
                <input
                  type="text"
                  class={`form-control ${
                    this.state.error.userLastName ? 'is-invalid' : ''
                  }`}
                  name="userLastName"
                  id="userLastName"
                  placeholder="Last name"
                  value={this.state.userLastName}
                  onChange={this.handleInputChange}
                />
                {/* <div class="invalid-feedback">Please enter your last name</div> */}
              </div>
              {/* <div class="form-group">
                <input
                  type="text"
                  class={`form-control ${this.state.error.userTitle ? 'is-invalid':''}`}
                  name="userTitle"
                  id="userTitle"
                  placeholder="Job title"
                  value={this.state.userTitle}
                  onChange={this.handleInputChange}
                  
                />
                <div class="invalid-feedback">Please enter your job title</div>
              </div> */}
              <div class="form-group">
                <label for="userCompany" class="d-none">
                  Organization name
                </label>

                <input
                  type="text"
                  class={`form-control ${
                    this.state.error.userCompany ? 'is-invalid' : ''
                  }`}
                  name="userCompany"
                  id="userCompany"
                  placeholder="Organization name"
                  value={this.state.userCompany}
                  onChange={this.handleInputChange}
                />
                {/* <div class="invalid-feedback">
                  Please enter your organization name
                </div> */}
              </div>
              <div class="form-group">
                <label for="userEmail" class="d-none">
                  Business email
                </label>

                <input
                  type="text"
                  class={`form-control ${
                    this.state.error.userEmail ? 'is-invalid' : ''
                  }`}
                  name="userEmail"
                  id="userEmail"
                  placeholder="Business email"
                  value={this.state.userEmail}
                  onChange={this.handleInputChange}
                />
                {/* <div class="invalid-feedback">
                  Please enter your business email
                </div> */}
              </div>
              <div class="form-group">
                <input
                  type="text"
                  class={`form-control ${
                    this.state.error.userPhone ? 'is-invalid' : ''
                  }`}
                  name="userPhone"
                  id="userPhone"
                  placeholder="Business phone"
                  value={this.state.userPhone}
                  onChange={this.handleInputChange}
                />
                {/* <div class="invalid-feedback">
                  Please enter your business phone
                </div> */}
              </div>
              <div class="form-group">
                <label for="userEmployees" class="d-none">
                  Number of employees
                </label>

                <input
                  type="text"
                  class={`form-control ${
                    this.state.error.userEmployees ? 'is-invalid' : ''
                  }`}
                  name="userEmployees"
                  id="userEmployees"
                  placeholder="# of employees"
                  value={this.state.userEmployees}
                  onChange={this.handleInputChange}
                />
                {/* <div class="invalid-feedback">
                  Please enter the number of employees
                </div> */}
              </div>
              {/* {Object.values(this.state.error).some((v) => v === true) ? (
                <small>
                  <div class="red-1">Please correct the form errors</div>
                </small>
              ) : null} */}

              <div class="pt-3">
                <button
                  type="submit"
                  class="btn btn-cta-reverse btn-lg btn-block"
                  onClick={(e) => this.handleValidation(e)}
                >
                  {this.props.buttontext}
                  {isLoading ? (
                    <FaSpinner
                      size={15}
                      className="icon-spin"
                      style={{ marginLeft: '10px' }}
                    />
                  ) : null}
                </button>
              </div>
            </form>
          </div>
        ) : (
          <div class="border-2-blue-1 bg-gray-7 rounded-corners mb-3 p-4 text-center">
            <h2 class="display-4 my-3 p-2">{this.props.complete}</h2>
            {this.props.completeurl ? (
              <a
                class="btn btn-primary"
                href={this.props.completeurl}
                target="_blank"
                rel="noopener noreferrer"
              >
                Download
              </a>
            ) : null}
          </div>
        )}
      </>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Snippet)
