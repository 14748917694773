//React
import React, { useState } from 'react'

//Gatsby
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

//Packages
import Helmet from 'react-helmet'
// import parse from 'url-parse'

//Components
import Layout from '../components/layoutLanding'
import OfferLeadForm from '../components/marketing/offerLeadForm'
// import ModalLandingDetail from '../components/marketing/modalLandingDetail'
import HowWorksVideo from '../components/marketing/howWorksVideo'
import LandingFeatures from '../components/marketing/landingFeatures'
import LandingVertical from '../components/marketing/landingVertical'

function replaceVertical(string, replacer) {
  console.log('string')
  console.log(string)
  console.log('replacer')
  console.log(replacer)
  let candidateString = string
  if (replacer) {
    candidateString = candidateString.replace(
      new RegExp('PREPPOSSVERTICAL', 'g'),
      replacer.preppossessivephrase
    )
    candidateString = candidateString.replace(
      new RegExp('POSSVERTICAL', 'g'),
      replacer.possessivephrase
    )
    candidateString = candidateString.replace(
      new RegExp('VERTICALNAME', 'g'),
      replacer.title
    )
    candidateString = candidateString.replace(
      new RegExp('SINGULARVERTICAL', 'g'),
      replacer.singular
    )
    candidateString = candidateString.replace(
      new RegExp('PLURALVERTICAL', 'g'),
      replacer.plural
    )
    candidateString = candidateString.replace(
      new RegExp('EMPLOYEEEXAMPLE', 'g'),
      replacer.employeeexample
    )
  } else {
    candidateString = candidateString.replace(
      new RegExp('PREPPOSSVERTICAL', 'g'),
      ''
    )
    candidateString = candidateString.replace(
      new RegExp('POSSVERTICAL', 'g'),
      ''
    )
    candidateString = candidateString.replace(
      new RegExp('VERTICALNAME', 'g'),
      ''
    )
    candidateString = candidateString.replace(
      new RegExp('SINGULARVERTICAL', 'g'),
      ''
    )
    candidateString = candidateString.replace(
      new RegExp('PLURALVERTICAL', 'g'),
      ''
    )
    candidateString = candidateString.replace(
      new RegExp('EMPLOYEEEXAMPLE', 'g'),
      ''
    )
  }

  return candidateString
}
function replaceBenefit(string, replacer) {
  console.log('string')
  console.log(string)
  console.log('replacer')
  console.log(replacer)
  let candidateString = string
  if (replacer) {
    candidateString = candidateString.replace(
      new RegExp('KEYWORDPLURAL', 'g'),
      replacer.keywordplural
    ) // replacer needs to be benefit
  } else {
    candidateString = candidateString.replace(
      new RegExp('KEYWORDPLURAL', 'g'),
      ''
    )
  }

  return candidateString
}

const videoLoader = [
  {
    id: 1,
    url: `https://${
      process.env.STAGE === 'dev'
        ? 'staging.picohealth.net'
        : process.env.HOSTNAME
    }/video/screener.MP4`,
    retinaurl: `https://${
      process.env.STAGE === 'dev'
        ? 'staging.picohealth.net'
        : process.env.HOSTNAME
    }/video/screener.MP4`,
  },
  {
    id: 2,
    url: `https://${
      process.env.STAGE === 'dev'
        ? 'staging.picohealth.net'
        : process.env.HOSTNAME
    }/video/console.MP4`,
    retinaurl: `https://${
      process.env.STAGE === 'dev'
        ? 'staging.picohealth.net'
        : process.env.HOSTNAME
    }/video/console.MP4`,
  },
]

const benefitText = [
  {
    title: 'Branded chatbot',
    content:
      'Users respond to prompts with photos & text about their brand experience in a customized chatbot - no download or apps required',
    activeVideo: 1,
    startTime: 0,
    endTime: 5.5,
  },

  {
    title: 'Upload content',
    content:
      'Users upload photos, videos, and text for their video through easy-to-use chat questions',
    activeVideo: 1,
    startTime: 5.5,
    endTime: 12,
  },

  {
    title: 'Email/SMS delivery',
    content:
      'Users receive videos near-instanteously to verified email addresses or mobile numbers',
    activeVideo: 2,
    startTime: 0,
    endTime: 16,
  },

  {
    title: 'Personalized, branded videos',
    content:
      'Storylava composes user photos &amp; text into a branded, themed video in seconds, with layout and music options you define.',
    activeVideo: 2,
    startTime: 16,
    endTime: 26,
  },

  {
    title: 'Easy social sharing',
    content:
      'Users drive awareness about your brand experience when they share videos through dedicated social landing pages',
    activeVideo: 2,
    startTime: 52,
    endTime: 60,
  },
]

const benefitSnippet = (benefit, vertical) => {
  return (
    <div class="offer-benefits mb-3 p-4 bg-sky-6 rounded-corners h-100">
      {/* <p class="lead">What you'll learn:</p> */}
      <p class="lead display-4 line-height-4 mt-2 pb-3">
        Picohealth helps reduce liability &amp; risk by recording employee
        health checks in an auditable log.
      </p>
      <ul class="text-left font-weight-light line-height-3">
        {benefit.benefits.map((benefititem) => (
          <li>
            <strong>{benefititem.highlight}</strong>{' '}
            <span>{replaceVertical(benefititem.text, vertical)}</span>
          </li>
        ))}
      </ul>
    </div>
  )
}
const template = (props) => {
  const [modalContext, setModalContext] = useState('about')
  const { pageContext } = props
  const { headline, subheadline, benefit, cta, vertical } = pageContext

  let generalVertical = false
  let verticalSingular
  let verticalPlural
  if (vertical && vertical.title && vertical.singular && vertical.plural) {
    verticalSingular = vertical.singular
    verticalPlural = vertical.plural
    if (vertical.title === 'organization') generalVertical = true
  }

  return (
    <Layout>
      <Helmet
        title={`Picohealth - ${
          generalVertical
            ? replaceVertical(headline.title, '')
            : replaceVertical(headline.title, vertical)
        }`}
        meta={[
          {
            name: 'description',
            content: `${subheadline.title}`,
          },
          {
            name: 'keywords',
            content:
              'Covid, Covid-19, health, health questionnaire, covid questions, return to work, pandemic, pandemic assessment questions, worker health question, workplace health questions, workplace health, company health, company safety',
          },
          {
            name: 'robots',
            content: 'noindex,nofollow',
          },
        ]}
      />

      <div class="gradient-callout-cousteau">
        <div class="container container-page my-0 py-4">
          <div class="row">
            <div class="col-lg-10 offset-lg-1 d-flex flex-column justify-content-center text-center text-lg-center">
              <h1 class="font-weight-light display-2 white-1">
                {generalVertical
                  ? replaceVertical(headline.title, '')
                  : replaceVertical(headline.title, vertical)}
              </h1>
              <h2 class="display-4 white-1 line-height-4">
                {subheadline.title}
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div class="container mt-4 mb-4">
        <div class="row">
          <div class="col-lg-12 text-center mb-3">
            {/* <p class="lead display-4 line-height-4 mt-2">
              Businesses are turning to Picohealth to help screen their
              employees for Covid-19 symptoms.
            </p> */}
            <p class="lead display-4 line-height-4 mt-2">
              {cta.id === 2 || cta.id === 3 ? (
                <>{replaceVertical(benefit.sub, vertical)} </>
              ) : null}

              {replaceVertical(cta.sub, vertical)}
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 d-flex flex-column align-items-center justify-content-center text-center">
            <div class="offer-image-container p-2 mt-2">
              {cta.id === 4 || cta.id === 5 ? (
                <Img
                  className={`border-1-gray-4 ${
                    cta.id === 4 || cta.id === 5 ? 'rounded-corners' : ''
                  }`}
                  fixed={props.data[`cta${cta.id}`].childImageSharp.fixed}
                  // loading="eager"
                  // fadeIn={false}
                />
              ) : null}
              {cta.id === 1 || cta.id === 2 || cta.id === 3 ? (
                <HowWorksVideo
                  context="brand"
                  title="Personalized brand event videos"
                  description="Activate marketing events by creating personalized, branded videos with
          user photos & videos. Take a tour below:"
                  content={benefitText}
                  videos={videoLoader}
                />
              ) : null}
            </div>
            <div class="offer-lead-form-mobile d-lg-none mt-3 d-flex flex-column">
              <h3>{cta.head}</h3>
              <OfferLeadForm
                text={cta.text}
                buttontext={cta.button}
                complete={cta.complete}
                completeurl={cta.completeurl}
                leadSource={`h:${headline.id}s:${subheadline.id}b:${benefit.id}c:${cta.id}v:${vertical.title}`}
              />
            </div>

            {/* <div class="offer-modal-container my-2">
              <hr size={1} />
              <button
                type="button"
                class="btn btn-link"
                data-toggle="modal"
                data-target="#landingModal"
                onClick={() => setModalContext('about')}
              >
                What is Picohealth?
              </button>
              <button
                type="button"
                class="btn btn-link"
                data-toggle="modal"
                data-target="#landingModal"
                onClick={() => setModalContext('benefits')}
              >
                {benefit.why}
              </button>
              <hr size={1} />
            </div> */}
          </div>
          <div class="col-lg-6 d-none d-lg-flex flex-column align-items-center justify-content-center mt-3 mt-lg-0">
            <h3 class="text-center">{cta.head}</h3>

            <OfferLeadForm
              text={cta.text}
              buttontext={cta.button}
              complete={cta.complete}
              completeurl={cta.completeurl}
              leadSource={`h:${headline.id}s:${subheadline.id}b:${benefit.id}c:${cta.id}v:${vertical.title}`}
            />
          </div>
        </div>

        {/* <div class="row mt-4">
          <div class="col-lg-6 d-flex flex-grow-1 flex-column align-items-center justify-content-start text-center">
            <LandingFeatures benefit={benefit} />
          </div>
          <div class="col-lg-6 d-flex flex-grow-1 flex-column align-items-center justify-content-start text-center">
            {benefitSnippet(benefit, vertical)}
          </div>
        </div> */}
      </div>

      {vertical && vertical.description ? (
        <LandingVertical
          benefit={benefit}
          vertical={vertical}
          verticaldescription={replaceBenefit(vertical.description, benefit)}
        />
      ) : null}

      <div class="gradient-callout-tropical mb-5">
        <div class="container container-page container-about-sub">
          <div class="row">
            <div class="col-lg-12 d-flex flex-column justify-content-center text-center text-lg-left">
              <h2 class="display-3 font-weight-medium white-1 text-center">
                <span>Features: </span>

                <span class="capitalize-initial">{benefit.keyword}</span>
              </h2>
              <LandingFeatures benefit={benefit} vertical={vertical} />
            </div>
          </div>
        </div>
      </div>

      <div class="gradient-callout-none">
        <div class="container container-page container-about-sub pb-3">
          <h2 class="display-3 font-weight-medium text-center pb-4">
            {/* <span>Picohealth can help your business</span> */}

            {generalVertical ? (
              <span style={{ textTransform: 'capitalize' }}>
                {benefit.keyword} - Benefits
              </span>
            ) : (
              <span>Benefits {vertical.preppossessivephrase}</span>
            )}
          </h2>
          <div class="row">
            <div class="col-lg-8 d-flex flex-column justify-content-center">
              {benefitSnippet(benefit, vertical)}
            </div>
            <div class="col-lg-4 p-2 pb-3 d-flex flex-column align-items-center justify-content-center ">
              {vertical.slug === 'bank' || vertical.slug === 'creditunion' ? (
                <Img fixed={props.data.useCaseBank.childImageSharp.fixed} />
              ) : null}
              {vertical.slug === 'construction' ? (
                <Img
                  fixed={props.data.useCaseConstruction.childImageSharp.fixed}
                />
              ) : null}
              {vertical.slug === 'homehealth' ? (
                <Img
                  fixed={props.data.useCaseHomeHealth.childImageSharp.fixed}
                />
              ) : null}
              {vertical.slug === 'assistedliving' ? (
                <Img
                  fixed={props.data.useCaseAssistedLiving.childImageSharp.fixed}
                />
              ) : null}
              {vertical.slug === 'organization' ? (
                <Img fixed={props.data.useCaseBusiness.childImageSharp.fixed} />
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <div class="gradient-callout-warm border-top-1-yellow-1">
        <div class="container container-page container-about-sub">
          <div class="row">
            <div class="col-lg-8 offset-lg-2 d-flex flex-column justify-content-center">
              <h3 class="text-center white-1 pb-2">
                <span class="capitalize-initial">Learn more</span>
              </h3>
              <OfferLeadForm
                text="We're here to answer your questions"
                buttontext="Get more info"
                complete={cta.complete}
                completeurl={cta.completeurl}
                leadSource={`h:${headline.id}s:${subheadline.id}b:${benefit.id}c:${cta.id}v:${vertical.title}-BOTTOM`}
              />
            </div>
          </div>
        </div>
      </div>

      {/* <ModalLandingDetail context={modalContext} benefit={benefit} /> */}
    </Layout>
  )
}
export default template

export const pageQuery = graphql`
  query {
    useCaseBusiness: file(
      relativePath: {
        eq: "stock/unsplash/bbh-singapore-HqhSi1Otubo-unsplash.jpg"
      }
    ) {
      childImageSharp {
        fixed(width: 300, height: 300, cropFocus: NORTH) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    useCaseConstruction: file(
      relativePath: {
        eq: "stock/unsplash/spencer-davis-YEmsw57gYa8-unsplash.jpg"
      }
    ) {
      childImageSharp {
        fixed(width: 300, height: 300, cropFocus: EAST) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    useCaseBank: file(
      relativePath: {
        eq: "stock/unsplash/sharon-mccutcheon--8a5eJ1-mmQ-unsplash.jpg"
      }
    ) {
      childImageSharp {
        fixed(width: 300, height: 300, cropFocus: EAST) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    useCaseHomeHealth: file(
      relativePath: {
        eq: "stock/unsplash/hush-naidoo-pA0uoltkwao-unsplash.jpg"
      }
    ) {
      childImageSharp {
        fixed(width: 300, height: 300, cropFocus: EAST) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    useCaseAssistedLiving: file(
      relativePath: {
        eq: "stock/unsplash/damir-bosnjak-VQ2L7t5qt_8-unsplash.jpg"
      }
    ) {
      childImageSharp {
        fixed(width: 300, height: 300, cropFocus: EAST) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    cta1: file(
      relativePath: {
        eq: "cta/screening/picohealth-reopening-risk-reduction.png"
      }
    ) {
      childImageSharp {
        fixed(width: 240, height: 240, cropFocus: NORTH) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    cta2: file(relativePath: { eq: "feature/screening-console-evaluate.png" }) {
      childImageSharp {
        fixed(width: 240, height: 240, cropFocus: NORTH) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    cta3: file(relativePath: { eq: "feature/screening-survey-employee.png" }) {
      childImageSharp {
        fixed(width: 240, height: 240, cropFocus: NORTH) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    cta4: file(
      relativePath: {
        eq: "stock/unsplash/linkedin-sales-navigator-wS73LE0GnKs-unsplash.jpg"
      }
    ) {
      childImageSharp {
        fixed(width: 300, height: 240, cropFocus: SOUTH) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    cta5: file(
      relativePath: { eq: "cta/screening/picohealth-state-assessment.png" }
    ) {
      childImageSharp {
        fixed(width: 300, height: 340, cropFocus: NORTH) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    ctadefault: file(
      relativePath: { eq: "feature/screening-survey-employee.png" }
    ) {
      childImageSharp {
        fixed(width: 240, height: 240, cropFocus: NORTH) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`

// export const query = graphql`
//   query($id: String) {
//     mdx(id: { eq: $id }) {
//       id
//       body
//       frontmatter {
//         date(formatString: "MMMM D, YYYY")
//         metatitle
//         metadesc
//         metakeywords
//         path
//         title
//         subtitle
//         featuredImage {
//           childImageSharp {
//             fluid(maxWidth: 1800, maxHeight: 500, cropFocus: CENTER) {
//               ...GatsbyImageSharpFluid
//             }
//           }
//         }
//       }
//     }
//   }
// `
