//React
import React from 'react'

//Packages
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

//Components
import NavMinimal from './nav/navMinimal'
import FooterMinimal from './nav/footerMinimal'

//Assets
import favicon from '../images/picohealth-icon.png'
import './../scss/main.scss'

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
  }

  static propTypes = {
    children: PropTypes.node.isRequired,
    location: PropTypes.node.isRequired,
  }

  componentDidMount() {}

  render() {
    const { children } = this.props

    return (
      <div class="min-vh-100 d-flex flex-column">
        <Helmet
          link={[
            { rel: 'shortcut icon', type: 'image/png', href: `${favicon}` },
          ]}
        />

        <NavMinimal />
        {children}
        <FooterMinimal />
      </div>
    )
  }
}

export default IndexPage
