//React
import React from 'react'

//Components
import FeatureIcon from './featureIcon'

const Snippet = (props) => (
  <div class="bg-sky-6 border-top-2-blue-1 pb-3 landing-vertical">
    <div class="container container-page container-about-sub">
      <div class="row">
        <div class="col-lg-3 d-flex flex-column align-items-center justify-content-center text-center text-lg-left mb-5 mb-lg-0">
          {props.vertical.slug === 'homehealth' ? (
            <FeatureIcon context="homehealth" color="dkblue" />
          ) : null}
          {props.vertical.slug === 'assistedliving' ? (
            <FeatureIcon context="assistedliving" color="dkorange" />
          ) : null}
          {props.vertical.slug === 'creditunion' ? (
            <FeatureIcon context="bank" color="dkgreen" />
          ) : null}
          {props.vertical.slug === 'bank' ? (
            <FeatureIcon context="bank" color="dkgreen" />
          ) : null}
          {props.vertical.slug === 'construction' ? (
            <FeatureIcon context="construction" color="dkorange" />
          ) : null}
        </div>
        <div class="col-lg-9 d-flex flex-column justify-content-center text-center text-lg-left">
          <h2 class="display-3 font-weight-medium">
            <span class="capitalize-initial">
              {props.benefit.keyword} {props.vertical.preppossessivephrase}
            </span>
          </h2>
          <p class="lead font-weight-light mt-2 pt-2">
            {props.verticaldescription}
          </p>
        </div>
      </div>
    </div>
  </div>
)

export default Snippet
