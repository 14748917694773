// import 'babel-polyfill';
// import 'isomorphic-fetch';
import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

import LoadingSpinner from '../loading'

import HowWorksVideoNumber from './howWorksVideoNumber'
import HowWorksVideoBrandDescription from './howWorksVideoBrandDescription'
import { FaArrowRight } from 'react-icons/fa'

import {
  MdCheck,
  MdOndemandVideo,
  MdPhonelinkRing,
  MdVolumeOff,
  MdVolumeUp,
} from 'react-icons/md'

// const agentParser = require("ua-parser-js")

export default class Snippet extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeVideo: 1,
      currentVideoTime: 0,
      currentVideo2Time: 0,
      currentPosition: 1,
      soundActivated: false,
      currentBrowser: '',
    }
  }
  componentDidMount() {
    // const agentObj = agentParser(window.navigator.userAgent)
    // this.setState({
    //   currentBrowser: agentObj.browser.name,
    // })
  }
  //   handleSoundActivation() {
  //     if (this.video2.muted === false) {
  //       this.setState({ soundActivated: false })
  //       this.video2.muted = true
  //     } else {
  //       this.setState({ soundActivated: true })
  //       this.video2.muted = false
  //     }
  //   }
  handleTimeUpdate(videoMode) {
    this.setState({
      currentVideoTime: this.video1.currentTime,
      currentVideo2Time: this.video2.currentTime,
    })
    if (
      this.state.activeVideo === 1 &&
      this.video1.currentTime >= 0 &&
      this.video1.currentTime < 17.5
    ) {
      this.setState({ currentPosition: 1 })
    } else if (
      this.state.activeVideo === 1 &&
      this.video1.currentTime >= 17.5
    ) {
      this.setState({
        activeVideo: 2,
        // currentPosition: 3,
        currentVideoTime: 0,
      })
      this.video1.pause()
      this.video1.currentTime = 0
      this.video2.play()
    } else if (
      this.state.activeVideo === 2 &&
      this.video2.currentTime >= 33.5
    ) {
      this.setState({ currentPosition: 1, activeVideo: 1 })
      this.video2.pause()
      this.video2.currentTime = 0
      this.video1.play()
    }
  }
  handleUpdatedPosition(currentPosition, activeVideo, startTime, endTime) {
    this.setState({
      currentPosition: currentPosition,
      activeVideo: activeVideo,
    })

    if (activeVideo === 1) {
      this.video1.currentTime = startTime
      this.video2.pause()
      this.video2.currentTime = 0
      this.video1.play()
    } else if (activeVideo === 2) {
      this.video2.currentTime = startTime
      this.video1.pause()
      this.video1.currentTime = 0
      this.video2.play()
    }
  }
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            workflowVideoFrame: file(
              relativePath: { eq: "feature/iphone-frame.png" }
            ) {
              childImageSharp {
                fixed(width: 275, height: 568, cropFocus: NORTH) {
                  ...GatsbyImageSharpFixed_withWebp_noBase64
                }
              }
            }
          }
        `}
        render={(data) => (
          <>
            {/* {this.state.currentPosition} \\
        {this.state.currentVideoTime} \\
        {this.state.currentVideo2Time} */}

            {/* <div class="row d-flex"> */}
            {/* <div class="col-md-4 p-0"> */}
            {/* <div class="d-block d-md-none">
                <HowWorksVideoBrandDescription
                    title={this.props.title}
                    description={this.props.description}
                  />
              </div> */}

            {/* BEGIN SECTION */}
            <div class="d-flex align-items-center justify-content-center videotour-container">
              {this.state.currentBrowser === 'IE' ? null : (
                <Img
                  alt="How it works"
                  fixed={data.workflowVideoFrame.childImageSharp.fixed}
                  style={{ position: 'absolute', zIndex: 10 }}
                  fadeIn={false}
                />
              )}

              {/* All the weird top/right/left/bottom styling is to support IE 11. See: https://stackoverflow.com/questions/22654528/how-to-center-a-position-absolute-element-in-ie-9-10-11 */}
              <div
                class="videotour"
                style={{
                  zIndex: -30,
                  width: 255,
                  height: 552,
                  top: 0,
                  right: 0,
                  left: 0,
                  bottom: 0,
                  margin: 'auto',
                  position: 'absolute',
                }}
              >
                <LoadingSpinner />
              </div>

              {this.props.videos.map((item, i) => (
                <video
                  ref={(e) => {
                    this[`video${i + 1}`] = e
                  }}
                  className={
                    this.state.activeVideo === i + 1 ? 'd-block' : 'd-none'
                  }
                  onTimeUpdate={this.handleTimeUpdate.bind(
                    this,
                    this.props.context
                  )}
                  width="255"
                  height="552"
                  playsInline
                  autoPlay
                  muted
                  controls="controls"
                  loop="loop"
                  style={{
                    zIndex: -10,
                    borderRadius: 20,
                    width: 255,
                    height: 552,
                  }}
                >
                  {typeof window !== `undefined` &&
                  window.devicePixelRatio >= 2 ? (
                    <source src={item.retinaurl} type="video/mp4" />
                  ) : (
                    <source src={item.url} type="video/mp4" />
                  )}
                </video>
              ))}
            </div>
            {/* </div> */}

            {/* <div class="col-md-7 d-flex flex-column align-items-center justify-content-center align-items-md-start justify-content-md-start"> */}
            {/* <div class="d-none d-md-block">
                  <HowWorksVideoBrandDescription
                    title={`Tour: ${this.props.title}`}
                    description={this.props.description}
                  />
                </div> */}
            {/* <div class="p-3 videotour-features">
                  <div
                    class="d-none d-md-block"
                    style={{
                      width: 0,
                      height: 0,
                      borderTop: '20px solid transparent',
                      borderBottom: '20px solid transparent',
                      borderRight: '20px solid #66aedf',
                      position: 'absolute',
                      left: -5,
                      top: 230,
                    }}
                  /> */}
            {/* <hr size="1" /> */}
            {/* <div class="d-flex flex-row align-items-center justify-content-center align-items-md-start justify-content-md-start">
                    {this.props.content.map((item, i) => (
                      <div
                        class="videotour-number-container"
                        onClick={() =>
                          this.handleUpdatedPosition(
                            i + 1,
                            item.activeVideo,
                            item.startTime,
                            item.endTime
                          )
                        }
                      >
                        <HowWorksVideoNumber
                          number={`${i + 1}`}
                          active={
                            this.state.currentPosition === i + 1 ? true : false
                          }
                        />
                      </div>
                    ))}
                  </div> */}
            {/* <div
                    style={{ borderBottom: '1px solid #e6e6e6', marginTop: 12 }}
                  />
                  {this.props.content.map((item, i) =>
                    this.state.currentPosition === i + 1 ? (
                      <div class="p-2 fade-in text-center text-md-left">
                        <h3>{item.title}</h3>
                        <div class="benefit-text">{item.content}</div>
                      </div>
                    ) : null
                  )}
                </div> */}

            {/* <div class="row d-flex flex-row align-items-end justify-content-center text-center mt-4 ml-2 flex-grow-1">
                  {this.props.context === 'brand' ? (
                    <Link to="/about/brands/" class="btn btn-cta btn-block">
                      Storylava for brand marketing{' '}
                      <FaArrowRight
                        style={{
                          color: '#307adb',
                          fontSize: 15,
                          marginBottom: 3,
                        }}
                      />
                    </Link>
                  ) : null}
                  {this.props.context === 'review' ? (
                    <Link
                      to="/about/testimonials/"
                      class="btn btn-cta btn-block"
                    >
                      Storylava for customer testimonials{' '}
                      <FaArrowRight
                        style={{
                          color: '#307adb',
                          fontSize: 15,
                          marginBottom: 3,
                        }}
                      />
                    </Link>
                  ) : null}
                </div> */}
            {/* </div> */}
            {/* </div> */}
          </>
        )}
      />
    )
  }
}
