//React
import React from 'react'

//Icons
import { FaClipboardList } from 'react-icons/fa'
import {
  MdSms,
  MdAccessTime,
  MdPhonelinkRing,
  MdAssessment,
  MdShortText,
  MdLock,
} from 'react-icons/md'

const Snippet = (props) => (
  <div class="offer-features mb-3 p-2 pb-3 white-1">
    {/* <p class="lead">
      Features:{' '}
      <span style={{ textTransform: 'capitalize' }}>
        {props.benefit.keyword}
      </span>
    </p> */}
    <p class="lead display-4 text-center line-height-4 mt-2 pb-3 white-1">
      Picohealth helps you screen your {props.vertical.employeeexample} for
      Covid-19 symptoms &mdash; in an automated way via text message. See how
      below:
    </p>
    <hr size={1} color="#ffffff" />
    <div class="text-center font-weight-light line-height-3">
      <div class="offer-feature d-flex flex-row">
        <div class="offer-feature-icon">
          <MdSms />
        </div>
        <div class="d-flex flex-row align-items-center justify-content-center">
          <div>
            <strong>Schedule push text messages</strong> to employees with a
            health screener, and segment by division or group
          </div>
        </div>
      </div>
      {/* <div class="offer-feature d-flex flex-row">
        <div class="offer-feature-icon">
          <MdAccessTime />
        </div>
        <div class="d-flex flex-row align-items-center justify-content-center">
          <div>
            <strong>
              Customize when health screener SMS messages are sent
            </strong>
            , by day and time
          </div>
        </div>
      </div> */}
      <div class="offer-feature d-flex flex-row">
        <div class="offer-feature-icon">
          <MdPhonelinkRing />
        </div>
        <div class="d-flex flex-row align-items-center justify-content-center">
          <div>
            <strong>Use 'response mode'</strong> where employees / visitors text
            in a keyword to receive a health screener
          </div>
        </div>
      </div>
      <div class="offer-feature d-flex flex-row">
        <div class="offer-feature-icon">
          <FaClipboardList />
        </div>
        <div class="d-flex flex-row align-items-center justify-content-center">
          <div>
            <strong>
              Auto-populate health survey with CDC-recommended questions,
            </strong>
            or use custom questions specific to your industry
          </div>
        </div>
      </div>
      <div class="offer-feature d-flex flex-row">
        <div class="offer-feature-icon">
          <MdAssessment />
        </div>
        <div class="d-flex flex-row align-items-center justify-content-center">
          <div>
            <strong>View analytics and audit log</strong> of employee responses
          </div>
        </div>
      </div>
      {/* <div class="offer-feature d-flex flex-row">
        <div class="offer-feature-icon">
          <MdShortText />
        </div>
        <div class="d-flex flex-row align-items-center justify-content-center">
          <div>
            <strong>Display custom messaging to employees</strong> based on
            their survey responses (e.g. report for work, call your supervisor)
          </div>
        </div>
      </div> */}
      <div class="offer-feature d-flex flex-row">
        <div class="offer-feature-icon">
          <MdLock />
        </div>
        <div class="d-flex flex-row align-items-center justify-content-center">
          <div>
            <strong>Maintain security and privacy</strong> with data encryption
            and admin access control
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Snippet
